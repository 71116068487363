<template>
    <div class="dialog-footer">
        <el-button class="cancel" @click="cancelAction">{{ cancelButtonText }}</el-button>
        <el-button class="primary" type="primary" @click="primaryAction" :loading="buttonLoading">{{
            primaryButtonText }}</el-button>
    </div>
</template>

<script>
import {
    Button
} from 'element-ui'
export default {
    components: {
        ElButton: Button,
    },
    props: {
        cancelButtonText: {
            type: String,
            default: '',
            required: false,
        },
        primaryButtonText: {
            type: String,
            default: '',
            required: false,
        },
        buttonLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        primaryAction() {
            this.$emit('clickPrimary')
        },
        cancelAction() {
            this.$emit('clickCancel')
        }
    },
}
</script>
<style scoped lang='scss'>
.dialog-footer {
    margin: 0 23px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel {}

    .primary {
        background-color: #2779FF;
    }
}
</style>